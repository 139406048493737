import Layout from '@/components/Layout';
import ArticleTemp from '@/components/modules/articleTemp';
import SEO from '@/components/seo';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const About = () => {
  return (
        <Layout>
            <SEO title={`事務所概要`} description={`みつわ合同事務所の概要と、所属司法書士の紹介ページです。`} />
            <ArticleTemp header={`みつわ合同事務所概要`} descText={``}>
                <section className="text-gray-500 body-font">
                    <div className="container px-3 py-2 mx-auto">
                        <div className="flex flex-col text-center w-full mb-2">
                            <dl className="lg:w-5/6 mx-auto text-base text-left md:text-2xl md:pl-15 lg:pl-28">
                                <div className='flex flex-wrap mb-2 leading-relaxed'>
                                    <dt className='mr-4'>名称</dt>
                                    <dd>司法書士法人みつわ合同事務所</dd>
                                </div>
                                <div className='flex flex-wrap mb-1 leading-relaxed'>
                                    <dt className='mr-4'>住所</dt>
                                    <dd>
                                        〒541−0051<br/>大阪市中央区備後町４丁目１番３号<br/>御堂筋三井ビルディングB1F<br/>
                                        <a href='https://www.google.co.jp/maps/place/%E5%8F%B8%E6%B3%95%E6%9B%B8%E5%A3%AB%E6%B3%95%E4%BA%BA%E3%81%BF%E3%81%A4%E3%82%8F%E5%90%88%E5%90%8C%E4%BA%8B%E5%8B%99%E6%89%80/@34.6855716,135.4981057,17z/data=!3m2!4b1!5s0x6000e6e2493ccfc3:0x5038564daab25750!4m5!3m4!1s0x6000e6e2493bec55:0x82343b1f908386a1!8m2!3d34.6855672!4d135.5002997?hl=ja&authuser=0' target='_blank' rel='noopener noreferrer' className='text-red-500 mx-2 hover:text-rose-300'>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className='mx-2'/>
                                        <span className='underline'>GoogleMapへ</span>
                                    </a>
                                    </dd>
                                     
                                </div>
                                <div className='flex flex-wrap leading-relaxed'>
                                    <dt className='mr-4'>TEL</dt>
                                    <dd>06-6222-7744</dd>
                                </div>
                                <div className='flex flex-wrap leading-relaxed'>
                                    <dt className='mr-4'>FAX</dt>
                                    <dd>06-6222-7745</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>
            </ArticleTemp>
            <ArticleTemp header={`スタッフ紹介`} descText={``}>
                <div className="flex flex-wrap m-1 text-lg md:text-xl">
                    <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
                        <div className="h-full flex items-center border-gray-200 border p-2 rounded-lg">
                            <StaticImage alt="team" className="w-16 h-16 bg-white object-cover object-center flex-shrink-0 rounded-full mr-4" src="../images/logo.png" />
                            <div className="flex-grow">
                                <p className="text-gray-500">所長</p>
                                <h2 className="text-gray-900 title-font font-medium">司法書士　髙瀬雅祥</h2>
                                <p className="text-gray-500">簡裁代理権認定</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
                        <div className="h-full flex items-center border-gray-200 border p-2 rounded-lg">
                        <StaticImage alt="team" className="w-16 h-16 bg-white object-cover object-center flex-shrink-0 rounded-full mr-4" src="../images/logo.png" />
                            <div className="flex-grow">
                                <p className="text-gray-500">副所長</p>
                                <h2 className="text-gray-900 title-font font-medium">司法書士　髙井雅弘</h2>
                                <p className="text-gray-500">簡裁代理権認定</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
                        <div className="h-full flex items-center border-gray-200 border p-2 rounded-lg">
                            <StaticImage alt="team" className="w-16 h-16 bg-white object-cover object-center flex-shrink-0 rounded-full mr-4" src="../images/logo.png" />
                            <div className="flex-grow">
                                <h2 className="text-gray-900 title-font font-medium">司法書士　山崎哲生</h2>
                                <p className="text-gray-500">簡裁代理権認定</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
                        <div className="h-full flex items-center border-gray-200 border p-2 rounded-lg">
                            <StaticImage alt="team" className="w-16 h-16 bg-white object-cover object-center flex-shrink-0 rounded-full mr-4" src="../images/logo.png" />
                            <div className="flex-grow">
                                <p className="text-gray-500">顧問　</p>
                                <h2 className="text-gray-900 title-font font-medium">司法書士 板谷雅孝</h2>
                                <p className="text-gray-500">簡裁代理権認定</p>
                            </div>
                        </div>
                    </div>
                    <div className='p-2 lg:w-2/3 md:w-1/2 w-full'>
                        <div className="h-full flex items-center p-2 rounded-lg">
                            <StaticImage alt="team" className="w-16 h-16 bg-white object-cover object-center flex-shrink-0 rounded-full mr-4" src="../images/logo.png" />
                            <div className="flex-grow">        
                                <h2 className="text-gray-900 title-font font-medium">事務スタッフ　他４名</h2>
                            </div>
                        </div>
                    </div>



                </div>
            </ArticleTemp>
        </Layout>
    )
};

export default About;
